<template>
  <b-container>
    <b-row align-h="center">
      <b-col cols="6">
        <b-card>
          <b-row>
            <b-col>
              <b-form-group label="NIP" label-for="nip">
                <b-form-input id="nip" v-model="form.nip" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <b-form-input id="name" v-model="form.name" required />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="form.email" required />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Contact" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="form.contact"
                  type="number"
                  onKeyPress="if(this.value.length==13) return false;"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Organization" label-for="organization">
                <v-select
                  id="organization"
                  v-model="selectedOrganization"
                  :options="optionsOrganization"
                  label="text"
                  required
                />
                <!-- Selected: <strong>{{ selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Role" label-for="role">
                <v-select
                  id="role"
                  v-model="selectedRole"
                  :options="optionsRole"
                  label="text"
                  required
                />
                <!-- Selected: <strong>{{ selectedRole }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Status" label-for="status">
                <v-select
                  id="status"
                  v-model="selectedStatus"
                  :options="optionsStatus"
                  label="text"
                  required
                />
                <!-- Selected: <strong>{{ selectedStatus }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <!-- submit and reset -->
      <b-col cols="6">
        <b-row>
          <b-col v-if="permissions.includes('employee-update')">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="updateDetails()"
              block
            >
              Update
            </b-button>
          </b-col>
          <b-col v-if="permissions.includes('employee-delete')">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              @click="deleteEmployees()"
              block
            >
              Delete
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BTab,
  BTabs,
  BAlert,
  BLink,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormRadio,
  BFormDatepicker,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";

export default {
  components: {
    ToastificationContent,
    Cleave,
    flatPickr,
    vSelect,
    BFormTextarea,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormRadio,
    BFormDatepicker,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    draggable,
    Prism,
    BContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: null,
      form: {
        nip: "",
        name: "",
        email: "",
        contact: null,
        selectedStatus: null,
        optionsStatus: [],
      },
      selectedOrganization: null,
      optionsOrganization: [],
      selectedRole: null,
      optionsRole: [],
      selectedStatus: null,
      optionsStatus: [
        { value: null, text: "Please select status" },
        { value: 1, text: "Active" },
        { value: 0, text: "Non-Active" },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      permissions: [],
      eventOrganization: null,
      eventDivision: null,
      errMessage: "",
    };
  },
  watch: {
    selectedOrganization: function (data) {
      if (data.value === this.eventOrganization.value) {
        this.selectedOrganization = this.eventOrganization;
      } else {
        this.eventOrganization = data;
        this.trigger(data.value);
      }
    },
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
    this.getOrganization();
    this.getRoles();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http
        .get("employees/" + id)
        .then((response) => {
          // console.log(response.data.data)
          this.id = response.data.data.id;
          this.form.nip = response.data.data.nip;
          this.form.name = response.data.data.name;
          this.form.email = response.data.data.email;
          this.form.contact = response.data.data.phone;
          this.selectedOrganization = {
            value: response.data.data.organization,
            text: response.data.data.organization_name,
          };
          //counter watcher
          this.eventOrganization = {
            value: response.data.data.organization,
            text: response.data.data.organization_name,
          };
          this.$http
            .get(
              "divisions/all?organization_id=" + response.data.data.organization
            )
            .then((res) => {
              // console.log(res.data.data)
              this.optionsDivision.push({
                value: null,
                text: "Please select 1 division",
              });

              var datas = res.data.data;
              console.log;
              var i;
              for (i = 0; i < datas.length; i++) {
                var $data = {
                  value: datas[i].id,
                  text: datas[i].name,
                };
                this.optionsDivision.push($data);
              }
            });
          this.selectedRole = {
            value: response.data.data.role_id,
            text: response.data.data.role_name,
          };
          if (response.data.data.status === 1) {
            this.selectedStatus = { value: 1, text: "Active" };
          } else {
            this.selectedStatus = { value: 0, text: "Non-Active" };
          }
          this.form.selectedStatus = {
            value: response.data.data.status_type,
            text: response.data.data.status_type_name,
          };
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getOrganization() {
      this.$http.get("organizations").then((res) => {
        this.optionsOrganization.push({
          value: null,
          text: "Please select 1 organization",
        });

        var datas = res.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.optionsOrganization.push($data);
        }
        // console.log(this.optionsOrganization)
      });
    },
    getRoles() {
      this.$http.get("user/roles").then((res) => {
        this.optionsRole.push({ value: null, text: "Please select role" });

        var datas = res.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.optionsRole.push($data);
        }
        // console.log(this.optionsRole)
      });
    },
    getEmployeeStatus() {
      this.$http.get("employee/status").then((response) => {
        this.form.optionsStatus.push({
          value: null,
          text: "Please select employee status",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsStatus.push($data);
        }
        // console.log(this.form.optionsStatus)
      });
    },
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    updateDetails() {
      this.$http
        .post("employees/" + this.id + "/update", {
          nip: this.form.nip,
          name: this.form.name,
          email: this.form.email,
          phone: this.form.contact,
          organization: this.selectedOrganization.value,
          role_id: this.selectedRole.value,
          status: this.selectedStatus.value,
          status_type: this.form.selectedStatus.value,
        })
        .then((response) => {
          location.href = "/users";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteEmployees($id) {
      this.$http
        .post("employees/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Employee has been Deleted",
              variant: "danger",
            },
          });
          location.href = "/users";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>